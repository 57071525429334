import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation userLogin2($email: String!, $password: String!) {
    userLogin2(email: $email, password: $password) {
      authenticatable {
        id
        image
        name
        accountStatus
      }
      credentials {
        accessToken
        client
        uid
      }
    }
  }
`;

export default LOGIN;
