import { gql } from "@apollo/client";

const GET_INVITED_CAMPAIGNS = gql`
  query getInvitedCampaignsForInfluencer($userId: ID!) {
    getInvitedCampaignsForInfluencer(userId: $userId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      startDate
      endDate
      commissionModel
      campaignContracts {
        id
        userId
        influencerQuote
        influencerQuoteType
        brandQuote
        brandQuoteType
        currentFlow
        currentNegotiator
        customContract
        contractType
      }
    }
  }
`;

export default GET_INVITED_CAMPAIGNS;
