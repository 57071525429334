import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { useQuery, useMutation } from "@apollo/client";
import GET_BRANDS from "../../../graphql/queries/getBrands"
import ADD_TO_WISHLIST from '../../../graphql/mutations/addToWishlist';

function BrandListingModal({ open, setOpen, currentUserId, wishlist, updateWishlist }) {
  const [brands, setBrands] = useState([]);
  const [addToWishlist, { loading, error }] = useMutation(ADD_TO_WISHLIST);


  useQuery(GET_BRANDS, {
    onCompleted: (res) => {
      const filteredBrands = res.getBrands.filter((brand) => isBrandNotInWishlist(brand, wishlist));
      setBrands(filteredBrands);
    },
  });

  function isBrandNotInWishlist(brand, wishlist) {
    return !wishlist.some((item) => item.brandId == brand.id);
  }

  const handleAddToWishlist = (brandId) => {
    addToWishlist({
      variables: {
        brandId: brandId,
        influencerId: currentUserId,
      },
    })
      .then((response) => {
        const updatedWishlist = [...wishlist, response.data.addBrandToWishlist];
        setOpen(false)
        updateWishlist(updatedWishlist);
      })
      .catch((error) => {
        // Handle mutation error here
        console.error('Error adding brand to wishlist:', error);
      });
  };


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="wishlist-modal-title"
      aria-describedby="wishlist-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <h2 id="wishlist-modal-title">Add Brands to Wishlist</h2>
        <List sx={{ maxHeight: 300, overflow: 'auto' }}>
          {brands.map((brand, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <Button variant="contained" onClick={() => handleAddToWishlist(brand?.id)}>
                  Add
                </Button>
              }
            >
              <ListItemText primary={brand?.brandName} />
            </ListItem>
          ))}
        </List>
        <Button variant="contained" onClick={handleClose}>Cancel</Button>
      </Box>
    </Modal>
  );
}

export default BrandListingModal
