import { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  imageBox: {
    width: 100,
    height: 100,
    margin: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));


const ImageGallery = ({ images }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {images && images?.map((image, index) => (
        <Grid item key={index}>
          <Box
            className={classes.imageBox}
            style={{ backgroundImage: `url(${image})` }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGallery;
