import { gql } from "@apollo/client";

const SHOW_GIFTED_INTEREST = gql`
  mutation influencerInterestInGiftedCampaign(
    $userId: ID!
    $campaignId: ID!
    $description: String!
    $commissionModel: Boolean
  ) {
    influencerInterestInGiftedCampaign(
      userId: $userId
      campaignId: $campaignId
      description: $description
      commissionModel: $commissionModel
    )
  }
`;

export default SHOW_GIFTED_INTEREST;
