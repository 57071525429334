import { gql } from "@apollo/client";

const GET_INTERESTED_CAMPAIGNS = gql`
  query getInterestedCampaignsForInfluencer($userId: ID!) {
    getInterestedCampaignsForInfluencer(userId: $userId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      commissionModel
      startDate
      endDate
      campaignContracts {
        id
        userId
        influencerQuoteType
        influencerQuote
        brandQuote
        brandQuoteType
        contractType
        currentFlow
        customContract
        currentNegotiator
        campaignTasks {
          description
        }
      }
    }
  }
`;

export default GET_INTERESTED_CAMPAIGNS;
