import { Helmet } from "react-helmet-async";
import { Grid, Container, Typography } from "@mui/material";
import { AppTasks } from "../sections/@dashboard/app";
import CampaignsPage from "./CampaignsPage";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  return (
    <>
      <Helmet>
        <title> Authentic | Influencer Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppTasks
              title="To Do"
              list={[
                { id: "instagram_insights", label: "Add Instagram Insights" },
                { id: "tiktok_insights", label: "Add TikTok Insight" },
                { id: "gallery", label: "Upload images to gallery" },
                { id: "campaigns", label: "Apply for a campaign" },
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <CampaignsPage />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
