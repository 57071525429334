import { gql } from "@apollo/client";

const RESPOND_TO_PAID_INVITE = gql`
  mutation influencerRespondPaidCampaign(
    $influencerId: ID!
    $campaignId: ID!
    $influencerQuote: Int!
  ) {
    influencerRespondPaidCampaign(
      campaignId: $campaignId
      influencerId: $influencerId
      influencerQuote: $influencerQuote
    )
  }
`;

export default RESPOND_TO_PAID_INVITE;
