import { gql } from "@apollo/client";

const SUBMIT_TIKTOK_STATS = gql`
  mutation submitTiktokStat(
    $userId: ID!
    $link: [String!]!
    $following: Int!
    $topCountry: String
    $topCountryPercentage: Int
    $topCity: String
    $topCityPercentage: Int
    $femaleRatio: Int
    $maleRatio: Int
    $accountsEngaged: Int
    $accountVisibility: Int
    $videoViews: Int
    $topAgeRange: String
    $videoViewsGrowthRate: String
    $profileViews: Int
    $profileViewsGrowthRate: String
    $likes: Int
    $likesGrowthRate: String
    $comments: Int
    $commentsGrowthRate: String
    $commission: String
    $commissionType: String

  ) {
    submitTiktokStat(
      userId: $userId
      link: $link
      following: $following
      topCountry: $topCountry
      topCountryPercentage: $topCountryPercentage
      topCity: $topCity
      topCityPercentage: $topCityPercentage
      femaleRatio: $femaleRatio
      maleRatio: $maleRatio
      accountsEngaged: $accountsEngaged
      accountVisibility: $accountVisibility
      videoViews: $videoViews
      topAgeRange: $topAgeRange
      videoViewsGrowthRate: $videoViewsGrowthRate
      profileViews: $profileViews
      profileViewsGrowthRate: $profileViewsGrowthRate
      likes: $likes
      likesGrowthRate: $likesGrowthRate
      comments: $comments
      commentsGrowthRate: $commentsGrowthRate
      commission: $commission
      commissionType: $commissionType
    )
  }
`;

export default SUBMIT_TIKTOK_STATS;
