import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button, DialogContent } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customDialog: {
    maxWidth: '631px', // Set your custom max width here
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HowToVideoPopup = ({title, description, youtubeId}) => {
  const classes = useStyles();
  const [openUploader, setOpenUploader] = useState(false);

  const handleClickOpen = () => {
    setOpenUploader(true);
  };

  const handleClose = () => {
    setOpenUploader(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        How to video
      </Button>

      <Dialog
        open={openUploader}
        keepMounted
        onClose={handleClose}
        classes={{ paper: classes.customDialog }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
            
            <iframe width="280" height="400" 
            style={{marginTop: "10px"}}

            src={`https://www.youtube.com/embed/${youtubeId}?rel=0`} 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media;
            gyroscope; picture-in-picture;
            web-share"
            allowfullscreen></iframe>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HowToVideoPopup;
