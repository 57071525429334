import { gql } from "@apollo/client";

const GET_ACTIVE_CAMPAIGN = gql`
  query influencerGetActiveCampaign($campaignId: ID!) {
    influencerGetActiveCampaign(campaignId: $campaignId) {
      id
      ageGroup
      followerPreference
      startDate
      endDate
      contract
      campaignType
      category {
        id
        name
      }
      coverPic
      description
      femalePercentage
      malePercentage
      media
      name
      status
      campaignContracts {
        id
        description
        finalFee
        customContract
        userId
      }
    }
  }
`;

export default GET_ACTIVE_CAMPAIGN;
