import { gql } from "@apollo/client";

const DELETE_TASK = gql`
  mutation influencerDeleteSubmitTask(
    $taskId: ID!
    $imageSubmission: String
    $videoSubmission: String
  ) {
    influencerDeleteSubmitTask(
      taskId: $taskId
      imageSubmission: $imageSubmission
      videoSubmission: $videoSubmission
    )
  }
`;

export default DELETE_TASK;
