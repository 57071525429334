import { gql } from "@apollo/client";

const SEND_MESSAGE = gql`
  mutation sendChatMessage(
    $chatroomId: ID!
    $userId: ID!
    $message: String
    $attachment: String
  ) {
    sendChatMessage(
      chatroomId: $chatroomId
      userId: $userId
      message: $message
      attachment: $attachment
    )
  }
`;

export default SEND_MESSAGE;
