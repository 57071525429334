import { gql } from "@apollo/client";

const GET_INFLUENCER_GALLERY = gql`
  query getInfluencerGallery($userId: ID!, $sortBy: String) {
    getInfluencerGallery(userId: $userId, sortBy: $sortBy) {
      id
      item
      createdAt
    }
  }
`;

export default GET_INFLUENCER_GALLERY;
