import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Button, Container, Stack, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
import { GalleryCard, GallerySort } from "../sections/@dashboard/gallery";
// mock
import { useQuery } from "@apollo/client";
import GET_INFLUENCER_GALLERY from "../graphql/queries/getInfluencerGallery";
import { useState } from "react";
import { GalleryUploader } from "../sections/@dashboard/gallery/GalleryUploader";
import HowToVideoPopup from "../components/HowToVideoPopup";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "latest", label: "Latest" },
  { value: "oldest", label: "Oldest" },
];

// ----------------------------------------------------------------------

export default function GalleryPage() {
  const [sortBy, setSortBy] = useState("latest");
  const [openUploader, setOpenUploader] = useState(false);

  const { data: gallery } = useQuery(GET_INFLUENCER_GALLERY, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      sortBy: sortBy,
    },
  });

  return (
    <>
      <Helmet>
        <title> My Gallery | Authentic Influencers </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            My Gallery
          </Typography>
          <HowToVideoPopup title="Upload to your gallery" description="Watch the video below to learn how to upload to your gallery" youtubeId="OSIJ6zSrQbc"/>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setOpenUploader(true)}
          >
            New Upload
          </Button>
          <GalleryUploader open={openUploader} setOpen={setOpenUploader} />
        </Stack>

        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <GallerySort
            options={SORT_OPTIONS}
            onSort={setSortBy}
            sortBy={sortBy}
          />
        </Stack>

        {gallery && (
          <Grid container spacing={3}>
            {gallery.getInfluencerGallery.map((post, index) => (
              <GalleryCard key={post.id} post={post} index={index} />
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
}
