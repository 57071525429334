import { gql } from "@apollo/client";

const GET_SURVEYS = gql`
  query getSurvey($userId: ID!) {
    getSurvey(userId: $userId) {
      id
      question
      answers
      coverImage
      createdAt
      surveyAnswers {
        id
        answer
        userId
      }
    }
  }
`;

export default GET_SURVEYS;
