import { gql } from "@apollo/client";

const ADD_TO_WISHLIST = gql`
  mutation addBrandToWishlist($brandId: ID, $influencerId: ID!, $brandName: String) {
    addBrandToWishlist(brandId: $brandId, influencerId: $influencerId, brandName: $brandName) {
      id
      brandId
      collaborated
      brandName
      brand {
        id
        name
        image
        brandName
      }
    }
  }
`;

export default ADD_TO_WISHLIST;
