import { gql } from "@apollo/client";

const GET_CAMPAIGNS_FOR_RESULTS = gql`
  query getMyCampaignsForResults($userId: ID!) {
    getMyCampaignsForResults(userId: $userId) {
      id
      name
      media
      includesStories
      includesReels
      includesVideos
      includesPictures
    }
  }
`;

export default GET_CAMPAIGNS_FOR_RESULTS;
