// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "Campaigns",
    path: "/campaigns",
    icon: icon("ic_cart"),
  },
  {
    title: "Insights",
    path: "/insights",
    icon: icon("ic_lock"),
  },
  {
    title: "Campaign Results",
    path: "/campaign-results",
    icon: icon("ic_blog"),
  },
  {
    title: "Chats",
    path: "/chats",
    icon: icon("ic_chat"),
  },
  {
    title: "Creator Surveys",
    path: "/creator-survey",
    icon: icon("ic_disabled"),
  },
  {
    title: "Gallery",
    path: "/gallery",
    icon: icon("ic_user"),
  },
  {
    title: "Wishlist",
    path: "/wishlist",
    icon: icon("ic_wishlist"),
  },
];

export default navConfig;
