import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  CircleStencil,
  FixedCropper,
  ImageRestriction,
} from "react-advanced-cropper";
import S3 from "aws-s3";
import "react-advanced-cropper/dist/style.css";
import config from "../../../config/s3_config";
import { useMutation } from "@apollo/client";
import UPDATE_USER_PROFILE from "../../../graphql/mutations/updateUserInfo";
import {imageQuality} from "../../../components/shared/imageQuality"

const S3Client = new S3(config);

export const ProfilePicUploader = ({ open, setOpen, currentPicture }) => {
  const cropperRef = useRef(null);
  const [profilePic, setProfilePic] = useState("");
  const [disableActions, setDisableActions] = useState(false);

  const [updateProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: (res) => {
      handleClose();
    },
    refetchQueries: ["getBrand"],
  });

  const handleSave = async () => {
    setDisableActions(true);
    const croppedImage = cropperRef.current.getCanvas().toDataURL();
    var byteString = atob(croppedImage.split(",")[1]);
    var mimeString = croppedImage.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }
    const finalImage = new Blob([ab], { type: mimeString });
    S3Client.uploadFile(finalImage)
      .then((data) => {
        localStorage.setItem("pic", data.location);
        updateProfile({
          variables: {
            userId: localStorage.getItem("currentUser"),
            image: data.location,
          },
        })
      }

      )
      .catch((err) => {});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (file) => {
    const isGoodQuality = await imageQuality(file);
    if (!isGoodQuality) {
      alert('Please upload a better quality image file.');
    }
    else{
      S3Client.uploadFile(file)
        .then((data) => {
          setProfilePic(data.location);
          setDisableActions(true);
        })
        .catch((err) => {});
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle align="center">Upload Profile Picture</DialogTitle>
      <DialogContent>
        <FixedCropper
          minHeight={400}
          minWidth={400}
          ref={cropperRef}
          src={profilePic || currentPicture}
          stencilSize={{
            width: 300,
            height: 300,
          }}
          stencilComponent={CircleStencil}
          stencilProps={{
            aspectRatio: 6 / 9,
            movable: false,
            resizable: true,
          }}
          imageRestriction={ImageRestriction.stencil}
          className={"cropper"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          component="label"
          sx={{ marginRight: 4 }}
          disabled={disableActions}
        >
          Upload New
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => handleUpload(e.target.files[0])}
          />
        </Button>
        <Button onClick={handleSave} disabled={!disableActions}>
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
