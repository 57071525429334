import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid, Typography, Fab, Box } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import DownloadIcon from '@mui/icons-material/Download';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from "@mui/material/styles";
import config from "../../../config/s3_config";

const AWS = require('aws-sdk');

AWS.config.update(config);

const s3 = new AWS.S3();


function getPresignedUrl(key, filename) {
  const params = {
    Bucket: config.bucketName,
    Key: key,
    Expires: 60, // URL expiry time in seconds
    ResponseContentDisposition: `attachment; filename="${filename}"`,
  };

  return s3.getSignedUrl('getObject', params);
}

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#f6f6f6",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #f6f6f6",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid #A8DDFD",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "0",
      //   left: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #97C6E3",
      //   borderLeft: "16px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   left: "-17px",
      // },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#e3effd !important;",
      // width: "80%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #e3effd",
      borderRadius: "10px",
      // "&:after": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "15px solid #f8e896",
      //   borderLeft: "15px solid transparent",
      //   borderRight: "15px solid transparent",
      //   top: "0",
      //   right: "-15px",
      // },
      // "&:before": {
      //   content: "''",
      //   position: "absolute",
      //   width: "0",
      //   height: "0",
      //   borderTop: "17px solid #dfd087",
      //   borderLeft: "16px solid transparent",
      //   borderRight: "16px solid transparent",
      //   top: "-1px",
      //   right: "-17px",
      // },
    },

    messageContent: {
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      position: "absolute",
      bottom: "-1px",
      right: "6px",
    },
    attachmentBox: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap', // Wrap content on smaller screens
    },
    attachmentImage: {
      maxWidth: '100%', // Make the image responsive within its container
      maxHeight: '200px', // Adjust the maximum height as needed
      objectFit: 'contain',
      borderRadius: '10px',
      marginRight: '16px', // Add margin for spacing
    },
    attachmentUrl: {
      maxWidth: '100%', // Make the URL text responsive within its container
      borderRadius: '10px',
      padding: '10px', // Add padding for touchability
      backgroundColor: 'lightgray', // Add a background color for contrast
    },
    downloadButton: {
      margin: '16px', // Add margin for spacing
      padding: '8px', // Add padding for touchability
      borderRadius: '10px',
      objectFit: 'contain',
    },
    orange: {
      color: deepOrange[500],
      backgroundColor: deepOrange[500],
      width: 4,
      height: 4,
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: 4,
      height: 4,
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

function downloadFile(url, filename) {
  const encodedUrl = url.replace(/ /g, '+');

  if(isImage(filename)){
    fetch(encodedUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
    .then(blob => {
        const localUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = localUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up the DOM
        URL.revokeObjectURL(localUrl);
      })
    .catch(e => console.error('Error in downloading file:', e));
  }else{
    const key = url.split("https://authentic-pps.s3-eu-west-2.amazonaws.com/")[1]
    url = getPresignedUrl(key, key)

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // Clean up the DOM
  }
}


function cleanURL(url) {
  const extensions = ['.docx', '.png', '.jpeg', '.jpg'];
  const parts = url?.split('/');
  const fileName = parts[parts?.length - 1];

  for (let ext of extensions) {
    const extIndex = fileName.indexOf(ext + '.');
    if (extIndex !== -1) {
      // If the extension is found, return the URL up to the end of the extension
      return fileName.substring(0, extIndex + ext.length);
    }
  }

  return url;
}

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

const videoTypes = ["mp4", "webm", "mov", "avi", "mkv", "quicktime"]; // Add more video extensions as needed
const videoMimeTypes = ["video/mp4", "video/webm", "video/quicktime"]; // Add more video MIME types as needed

function isVideo(attachment) {
  const fileType = getFileType(attachment)
  return videoTypes.includes(fileType) || videoMimeTypes.includes(fileType);
}


function getFileType(attachment){
  return attachment.split(/[#?]/)[0].split(".").pop().trim();
}

const AttachmentBox = ({ attachment, attachmentUrl, downloadFile }) => {
  const classes = useStyles();

  const StyledVideo = styled("video")({
    maxWidth: '100%', // Make the image responsive within its container
    maxHeight: '200px', // Adjust the maximum height as needed
    objectFit: 'contain',
    borderRadius: '10px',
    marginRight: '16px', // Add m
    });
  return (
   <Box className={classes.attachmentBox}>
    {isImage(attachmentUrl) || isVideo(attachmentUrl) ? (
      <div>
        {isImage(attachmentUrl) && (
          <img
            src={attachment}
            alt="Attachment"
            className={classes.attachmentImage}
          />
        )}
        {isVideo(attachmentUrl) && (
          <StyledVideo src={attachment} type="video/mp4" controls loop />
        )}
      </div>
    ) : (
      <div className={classes.attachmentUrl}>
        <p>
          {attachmentUrl.substring(0, 40)}...
        </p>
      </div>
    )}
    <Fab
      color="#00e397"
      aria-label="add"
      onClick={() => downloadFile(attachment, attachmentUrl)}
      className={classes.downloadButton}
    >
      <DownloadIcon />
    </Fab>
  </Box>

  );
};

export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const attachment = props.attachment ? props.attachment : ""
  const attachmentName = cleanURL(attachment)
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:400px)');

  return (
    <>
      <Grid item xs container>
        <div className={classes.messageBlue} style={{width: isSmallScreen ? '90%': '50%'}}>
          <Typography variant="subtitle1" className={classes.messageContent}>
            {message}
          </Typography>
          {attachment && (
            <AttachmentBox
              attachment={attachment}
              attachmentUrl={attachmentName}
              downloadFile={downloadFile}
            />
          )}
          <br />
          <Typography
            variant="caption"
            className={classes.messageTimeStampRight}
          >
            {new Date(timestamp).toLocaleTimeString()}
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export const MessageRight = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const attachment = props.attachment ? props.attachment : ""
  const attachmentName = cleanURL(attachment)
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Grid item xs container justifyContent="end">
      <div className={classes.messageOrange} style={{width: isSmallScreen ? '90%': '50%'}}>
        <Typography variant="subtitle1" className={classes.messageContent}>
          {message}
        </Typography>
        {attachment && (
          <AttachmentBox
            attachment={attachment}
            attachmentUrl={attachmentName}
            downloadFile={downloadFile}
          />
        )}
        <br />
        <Typography variant="caption" className={classes.messageTimeStampRight}>
          {new Date(timestamp).toLocaleTimeString()}
        </Typography>
      </div>
    </Grid>
  );
};
