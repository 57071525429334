import { gql } from "@apollo/client";

const SUBMIT_TASK = gql`
  mutation influencerSubmitTask(
    $taskId: ID!
    $imageSubmission: String
    $videoSubmission: String
  ) {
    influencerSubmitTask(
      taskId: $taskId
      imageSubmission: $imageSubmission
      videoSubmission: $videoSubmission
    )
  }
`;

export default SUBMIT_TASK;
