import React from "react";
import {
  ListItemIcon,
  ListItemText,
  Avatar,
  List,
  ListItem,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import GET_BRAND_FOR_CHAT from "../../../graphql/queries/getBrandForChat";
import { CircularLoader } from "../../../components/loaders";
import useMediaQuery from '@mui/material/useMediaQuery';

const ChatRoomTile = ({
  brandId,
  selectChat,
  campaignName,
  selected,
  chatroom
}) => {
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const { data } = useQuery(GET_BRAND_FOR_CHAT, {
    variables: {
      userId: brandId,
    },
  });

  if (data === undefined) return <CircularLoader title="Loading Chat..." />;
  return (
    <List
      key={`${data.getBrand.id}-${data.getBrand.name}`}
      sx={{
        marginBottom: 1,
        paddingTop: 0,
        backgroundColor: selected ? "#E0E0E0" : "##FFF",
        borderRadius: 2,
        "&:hover": { backgroundColor: "#f0eded", cursor: "pointer" },
        width: '98.5%',
        borderBottom: 'solid 2px #E0E0E0'
      }}
      onClick={() => selectChat(chatroom.id, data.getBrand)}
    >
      <ListItemText
        primary={campaignName}
        sx={{
          color: "#FFF",
          backgroundColor: "#FFA3C7",
          px: 1,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      />
      <ListItem>
        {!isSmallScreen && <ListItemIcon>
          <Avatar
            alt={data.getBrand.name}
            src={data.getBrand.image}
          />
        </ListItemIcon>}
        <ListItemText
          primary={data.getBrand.name}
          secondary={chatroom?.lastMessage?.text}
        />
      </ListItem>
    </List>
  );
};

export default ChatRoomTile;
