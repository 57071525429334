import { gql } from "@apollo/client";

const GET_MY_TASK = gql`
  query influencerGetTasks($campaignId: ID!, $userId: ID!) {
    influencerGetTasks(campaignId: $campaignId, userId: $userId) {
      id
      campaignContractId
      description
      imageSubmissions
      status
      videoSubmissions
      deletedAt
      dueDate
    }
  }
`;

export default GET_MY_TASK;
