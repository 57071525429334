import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Link, Container, Typography, Divider } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
// hooks
import useResponsive from "../hooks/useResponsive";
import { Link as RouterLink } from "react-router-dom";
// components
import Logo from "../components/logo";
// sections
import SignupForm from "../sections/auth/SignupForm";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const collageData1 = [
  {
    img: "https://authentic.lon1.cdn.digitaloceanspaces.com/landing-page-images/5.png",
    title: "influencer-img-1",
  },
  {
    img: "https://authentic.lon1.cdn.digitaloceanspaces.com/landing-page-images/1.jpeg",
    title: "influencer-img-2",
  },
  {
    img: "https://i.ibb.co/TLTktkf/277453632-1037738147137237-5133846530842634878-n.jpg",
    title: "influencer-img-3",
  },
  {
    img: "https://authentic.lon1.cdn.digitaloceanspaces.com/landing-page-images/7.png",
    title: "influencer-img-4",
  },
  {
    img: "https://authentic.lon1.cdn.digitaloceanspaces.com/landing-page-images/9.jpg",
    title: "influencer-img-5",
  },
  {
    img: "https://i.ibb.co/FW8kMZf/Nina-Content-for-Mermade-Hair-2.jpg",
    title: "influencer-img-6",
  },
];
// ----------------------------------------------------------------------

export default function SignupPage() {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title> Signup | Authentic Influencers </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <StyledSection>
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Sign up for Authentic
              </Typography>

              <Typography variant="body2" sx={{ mb: 1 }}>
                Already have an account? &nbsp;
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                  Sign In
                </Link>
              </Typography>

              <Divider sx={{ my: 3 }} />

              <SignupForm />
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 5 }}
              >
                {"Copyright © "}
                <Link color="inherit" href="https://authentic-influencers.com">
                  Authentic Influencers
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </StyledContent>
          </Container>
        </StyledSection>
        {mdUp && (
          <Container sx={{ width: "100%" }}>
            <Masonry columns={3} spacing={2} defaultHeight={450}>
              {collageData1.map((item, index) => (
                <div key={index}>
                  <img
                    src={`${item.img}?w=162&auto=format`}
                    srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      display: "block",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </Masonry>
          </Container>
        )}
      </StyledRoot>
    </>
  );
}
