import PropTypes from "prop-types";
import { Grid, Link } from "@mui/material";
import PaidCampaignCard from "./PaidCampaignCard";
import { Link as RouterLink } from "react-router-dom";
import GiftedCampaignCard from "./GiftedCampaignCard";
import { useEffect } from "react";

// ----------------------------------------------------------------------

CampaignList.propTypes = {
  campaigns: PropTypes.array.isRequired,
};

export default function CampaignList({ campaigns, activeFilter, influencer, ...other }) {
  useEffect(() => {}, [campaigns]);
  return (
    <Grid container spacing={3} {...other}>
      {campaigns.map((campaign) => (
        <Grid key={campaign.id} item xs={12} sm={6} md={4}>
          <Link
            to={
              campaign.status === "In_Progress"
                ? `/campaign/${campaign.id}`
                : ""
            }
            component={RouterLink}
            sx={{
              textDecoration: "none",
            }}
          >
            {campaign.campaignType === "Paid" ? (
              <PaidCampaignCard
                campaign={campaign}
                activeFilter={activeFilter}
                influencer={influencer}
              />
            ) : (
              <GiftedCampaignCard
                campaign={campaign}
                activeFilter={activeFilter}
                influencer={influencer}
              />
            )}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
