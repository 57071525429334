import { gql } from "@apollo/client";

const FINISH_SIGNUP = gql`
  mutation influencerFinishSignup(
    $id: ID!
    $name: String
    $image: String
    $instagram: String
    $tiktok: String
    $facebook: String
    $country: String
    $city: String
    $theme: Int
    $categories: [ID!]
  ) {
    influencerFinishSignup(
      id: $id
      name: $name
      image: $image
      instagram: $instagram
      tiktok: $tiktok
      facebook: $facebook
      country: $country
      city: $city
      theme: $theme
      categories: $categories
    )
  }
`;

export default FINISH_SIGNUP;
