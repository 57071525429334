import { gql } from "@apollo/client";

const ACCEPT_OFFER = gql`
  mutation influencerAcceptPaidContract(
    $influencerId: ID!
    $campaignId: ID!
    $finalFee: Int!
    $finalFeeType: String

  ) {
    influencerAcceptPaidContract(
      campaignId: $campaignId
      influencerId: $influencerId
      finalFee: $finalFee
      finalFeeType: $finalFeeType
    )
  }
`;

export default ACCEPT_OFFER;
