import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Divider,
  List,
  CardHeader,
  Avatar,
  IconButton,
  Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularLoader } from "../components/loaders";
import ChatRoomTile from "../sections/@dashboard/chats/chatroomTile";
import Chatroom from "../sections/@dashboard/chats/chatroom";
import GET_INFLUENCER_CHATROOMS from "../graphql/queries/influencerGetChatrooms";
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChatBanner from "../assets/chat-banner.jpeg"

const useStyles = makeStyles({
  table: {
    minWidth: 750,
    width: "100%",
  },
  chatSection: {
    width: "100%",
    height: "80vh",
    overflow: "hidden"
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
  banner: {
    padding: "20px 120px 20px 20px",
    width: "100%",
    backgroundImage: `url(${ChatBanner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
    color: "#2d3436",
    marginBottom: 40,
    objectFit:"contain"
  },
});

const Chat = () => {
  const classes = useStyles();
  const [chatrooms, setChatrooms] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const username = localStorage.getItem("name");
  const profilePic = localStorage.getItem("pic");
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [currentBrand, setCurrentBrand] = useState([])
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useQuery(GET_INFLUENCER_CHATROOMS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setChatrooms(res.influencerGetChatrooms);
    },
  });

  const handleChatSelection = (chatId, brand) => {
    setCurrentBrand(brand);
    setSelectedChat(chatId);
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (sidebarOpen) {
      setSelectedChat("");
    }
  })

  if (chatrooms.length === 0)
    return <CircularLoader title="No Chats Available" />;
  return (
    <Grid item xs={12} pl={3} pr={2} sx={{ overflow: 'hidden' }}>
      <Box className={classes.banner} sx={{height: isSmallScreen ? '15vh' : '25vh'}} />
      <Grid item xs container component={Paper} sx={{ height: '90%', overflow: 'hidden' }}>
        {sidebarOpen && (
          <Grid item xs={sidebarOpen ? 12 : 1} className={classes.borderRight500}>
            <CardHeader
              avatar={
                <Avatar
                  alt={profilePic}
                  src={username}
                />
              }
              title={!isSmallScreen && username}
              action={selectedChat &&
                <IconButton
                  onClick={toggleSidebar}
                 >
                  {sidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                </IconButton>
              }
              sx={{padding: '5px'}}
            />
            <Divider />
            <List sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
              {chatrooms.map((chatroom, index) => (
                <ChatRoomTile
                  brandId={chatroom.userId}
                  selectChat={(chatroomId, brand) => handleChatSelection(chatroom.id, brand)}
                  key={`${chatroom.userId}-${chatroom.id}-${index}`}
                  campaignName={chatroom?.campaign?.name}
                  campaignType={chatroom?.campaign?.campaignType}
                  selected={selectedChat === chatroom.id}
                  chatroom={chatroom}
                />
              ))}
            </List>
          </Grid>
        )}
        {!sidebarOpen && (
          <IconButton
            onClick={toggleSidebar}
            sx={{ position: 'absolute', padding: '10px' }}
          >
            {sidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </IconButton>
        )}
        {selectedChat && <Chatroom id={selectedChat} brand={currentBrand}
                          sidebarOpen={sidebarOpen}
                          handleSidebar={toggleSidebar}
                          chatroom={chatrooms.find((chatroom) => chatroom.id == selectedChat)}/>}
      </Grid>
    </Grid>
  );
};

export default Chat;
