import React from "react";
import { Grid } from "@mui/material";
import UserBanner from "../sections/@dashboard/profile/userBanner";
import ArchiveCampaigns from "../sections/@dashboard/campaigns/ArchiveCampaigns";

const currentUser = localStorage.getItem("currentUser");

const Profile = () => {
  return (
    <>
      <Grid item xs container justifyContent="center">
        <UserBanner user={currentUser} />
      </Grid>

      <Grid item xs={12}>
        <ArchiveCampaigns />
      </Grid>
    </>
  );
};

export default Profile;
