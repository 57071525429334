import { gql } from "@apollo/client";

const Influencer_Image_Analysis_Result = gql`
  mutation influencerImageAnalysisResult(
    $images: [String!]!, 
    $influencerId: ID!,
    $statsType: Boolean!
  ) {
    influencerImageAnalysisResult(
      images: $images, 
      influencerId: $influencerId,
      statsType: $statsType){
      stats
    }
  }
`;

export default Influencer_Image_Analysis_Result;
