import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  Box,
  Card,
  Typography,
  Stack,
  Modal,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { getCurrencySymbol } from '../../../components/fields/currencies';

import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";

import Label from "../../../components/label";
import { useEffect, useState } from "react";
import NOT_INTERESTED from "../../../graphql/mutations/notInterestedInCampaign";
import ACCEPT_OFFER from "../../../graphql/mutations/influencerAcceptPaidContract";
import RESPOND_TO_PAID_INVITE from "../../../graphql/mutations/respondToPaidInvite";
import SHOW_PAID_INTEREST from "../../../graphql/mutations/interestedInPaidCampaign";
import REJECT_CAMPAIGN from "../../../graphql/mutations/rejectCampaign";
import RestrictedCampaign from "./RestrictedCampaign";
import CurrencyType from "../../../components/fields/currencyType";
import { formatDate } from "../../../components/dateFormatter";
// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "top",
  position: "absolute",
});

// ----------------------------------------------------------------------
const campaignMedia = {
  All: "TikTok & Instagram",
  TikTok: "TikTok",
  Instagram: "Instagram",
};
PaidCampaignCard.propTypes = {
  product: PropTypes.object,
};
const userAgent =
  typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
function debounce(func, delay = 250) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
export default function PaidCampaignCard({ campaign, activeFilter, influencer }) {
  const {
    id,
    name,
    coverPic,
    campaignType,
    media,
    contract,
    campaignContracts,
    description,
    includesStories,
    includesReels,
    includesVideos,
    includesPictures,
    status,
    commissionModel,
    startDate,
    endDate,
    user
  } = campaign;
  const debouncedUserInput = debounce((e) => setDescription(e));
  const [influencerCommissionModel, setInfluencerCommissionModel] = useState(false);
  const [myQuote, setMyQuote] = useState(0);
  const [currencyType, setCurrencyType] = useState('USD');
  const [agreed, setAgreed] = useState(false);
  const [myDescription, setDescription] = useState("");
  const [openInterestModal, setOpenInterestModal] = useState(false);
  const [openRestrictedModal, setOpenRestrictedModal] = useState(false);
  const [brandQuote, setBrandQuote] = useState(0);
  const [brandQuoteType, setBrandQuoteType] = useState();
  const [influencerQuote, setInfluencerQuote] = useState(0);
  const [defaultContract, setDefaultContract] = useState("");
  const [extendedContract, setExtendedContract] = useState("");
  const [influencerContract, setInfluencerContract] = useState("");
  const [extendedInfluencerContract, setExtendedInfluencerContract] = useState("");
  const [currentFlow, setCurrentFlow] = useState(1);
  const [currentNegotiator, setCurrentNegotiator] = useState();
  const [viewContractModal, setViewContractModal] = useState(false);
  const [showInterest] = useMutation(SHOW_PAID_INTEREST, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
      influencerQuote: parseInt(myQuote),
      influencerQuoteType: currencyType,
      description: myDescription,
      commissionModel: influencerCommissionModel,
    },
    onCompleted: () => {
      setMyQuote(0);
      setCurrencyType("");
      setDescription("");
      setOpenInterestModal(false);
      window.location.reload();
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [respondToInvite] = useMutation(RESPOND_TO_PAID_INVITE, {
    variables: {
      influencerQuote: parseInt(myQuote),
      influencerQuoteType: currencyType,
      influencerId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
    onCompleted: () => {
      setOpenInterestModal(false);
    },
  });
  const [notInterested] = useMutation(NOT_INTERESTED, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [rejectCampaign] = useMutation(REJECT_CAMPAIGN, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [acceptOffer] = useMutation(ACCEPT_OFFER, {
    variables: {
      campaignId: id,
      influencerId: localStorage.getItem("currentUser"),
      finalFee: brandQuote,
      finalFeeType: brandQuoteType
    },
    onCompleted: () => {
      setMyQuote(0);
      setDescription("");
      setCurrencyType("")
      setOpenInterestModal(false);
      setAgreed(false);
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const loadExtendedContract = async (contractLink) => {
    await fetch(contractLink).then((r) => {
      r.text().then((d) => {
        setExtendedContract(d);
      });
    });
  };
  const loadContract = async () => {
    await fetch(
      "https://authentic.lon1.cdn.digitaloceanspaces.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };
  const loadInfluencerContract = async (contractLink) => {
    await fetch( contractLink).then((r) => {
      r.text().then((d) => {
        setExtendedInfluencerContract(d);
      });
    });
  }
  const handleInterest = () => {
    if(influencer.isActive){
      handleInvite()
    }
    else{
      setOpenRestrictedModal(true);
    }
  };

  const handleInvite = () => {
    loadContract();
    contract && loadExtendedContract(contract);
    setOpenInterestModal(true);
  };

  const handleViewContract = () => {
    loadContract();
    contract && loadExtendedContract(contract);
    influencerContract && loadInfluencerContract(influencerContract)
    setViewContractModal(true);
  }

  useEffect(() => {
    if (
      (activeFilter === "Invited" || activeFilter === "Interested") &&
      brandQuote === 0 &&
      influencerQuote === 0
    ) {
      const currentUser = localStorage.getItem("currentUser");
      campaignContracts.forEach((contract) => {
        if (contract.userId.toString() === currentUser.toString()) {
          setBrandQuote(contract.brandQuote || "");
          setBrandQuoteType(contract.brandQuoteType || "");
          setInfluencerQuote(contract.influencerQuote || "");
          setCurrencyType(contract.influencerQuoteType || "");
          setCurrentFlow(contract.currentFlow)
          setCurrentNegotiator(contract.currentNegotiator)
          setInfluencerContract(contract.customContract)
        }
      });
    }
  }, [campaignContracts, activeFilter, brandQuote, brandQuoteType, influencerQuote, currencyType]);



  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Label
          variant="filled"
          color={(campaignType === "Paid" && "success") || "primary"}
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 2,
          }}
        >
          <CurrencyPoundIcon />
        </Label>
        <Typography
          variant="subtitle1"
          sx={{
            zIndex: 9,
            bottom: 0,
            left: 0,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 1,
            background: "rgba(0, 0, 0, 0.7)",
            width: "100%",
          }}
        >
          {user && user.brandName ? `${user.brandName} - ${name}` : name}
        </Typography>
        <StyledProductImg alt={name} src={coverPic} />
      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Typography variant="subtitle1" noWrap align="center">
          {campaignMedia[media]} Campaign
        </Typography>

        <Grid item xs container direction="column" alignItems="center">
          { startDate && <Typography variant="caption">Start Date: {formatDate(startDate)}</Typography> }
          { endDate && <Typography variant="caption">End Date: {formatDate(endDate)}</Typography> }
        </Grid>
        {status === "Cancelled" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Cancelled
          </Typography>
        )}

        {status === "In_Progress" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Click To Submit Content
          </Typography>
        )}

        {status !== "In_Progress" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Not Yet Started
          </Typography>
        )}

        {status === "Approved" && activeFilter === "Active" && (
          <Link
            to={
              `/campaign/${campaign.id}/details`
            }
            component={RouterLink}
            sx={{
              textDecoration: "none",
              alignSelf:"center"
            }}
            color="error"
            variant="caption"
          >
            View Campaign Details
          </Link>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {activeFilter === "Interested" && (
            <Grid item xs container direction="column">
              {influencerQuote && (
                <>
                  <Typography variant="subtitle1" noWrap align="center" mb={1}>
                    My Offer: {getCurrencySymbol(currencyType)}{influencerQuote}
                  </Typography>
                  <Grid item xs container justifyContent="space-between">
                    {brandQuote && (
                      <>
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          sx={{ width: "100%" }}
                          mb={1}
                        >
                          Brand Offer: {getCurrencySymbol(brandQuoteType)}{brandQuote}
                        </Typography>

                        <Button
                          variant="outlined"
                          onClick={() => acceptOffer()}
                          size="small"
                        >
                          Accept
                        </Button>

                        {brandQuote && influencerQuote && currentFlow < 4 && currentNegotiator == "brand" && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleInterest()}
                            size="small"
                          >
                            Resubmit
                          </Button>
                        )}


                      {brandQuote && influencerQuote && currentFlow == 4 && currentNegotiator == "brand" && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleViewContract()}
                            size="small"
                          >
                            View Contract
                          </Button>
                        )}

                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => rejectCampaign()}
                          size="small"
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {(activeFilter === "New" || activeFilter === "Old") && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={() => handleInterest()}
                sx={{
                  "&:hover": {
                    color: "#FFA3C7",
                  },
                }}
              >
                <Typography variant="caption">Interested &nbsp;</Typography>
                <FavoriteIcon fontSize="small" color="primary" />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={() => notInterested()}
                sx={{
                  "&:hover": {
                    color: "#FF4842",
                  },
                }}
              >
                <Typography variant="caption">Not Interested &nbsp;</Typography>
                <CancelIcon fontSize="small" color="error" />
              </Stack>
            </>
          )}
          {activeFilter === "Invited" && (
            <>
              {
                <Grid item xs container direction="column">
                  {
                    <Grid item xs container justifyContent="space-between">
                      {influencerQuote && (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          sx={{ width: "100%" }}
                          mb={1}
                        >
                          My Offer: {getCurrencySymbol(currencyType)}{influencerQuote}
                        </Typography>
                      )}
                      {brandQuote && (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          mb={1}
                          sx={{ width: "100%" }}
                        >
                          Brand Offer: {getCurrencySymbol(brandQuoteType)}{brandQuote}
                        </Typography>
                      )}
                      
                      {!brandQuote && !influencerQuote && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => handleInvite()}
                          sx={{
                            "&:hover": {
                              color: "#FFA3C7",
                            },
                          }}
                        >
                          <Typography variant="caption">
                            Respond &nbsp;
                          </Typography>
                          <FavoriteIcon fontSize="small" color="primary" />
                        </Stack>
                      )}
                      {brandQuote && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => acceptOffer()}
                          sx={{
                            "&:hover": {
                              color: "#FFA3C7",
                            },
                          }}
                        >
                          <Typography variant="caption">
                            Accept &nbsp;
                          </Typography>
                          <FavoriteIcon fontSize="small" color="primary" />
                        </Stack>
                      )}


                      {brandQuote && influencerQuote && currentFlow < 4 && currentNegotiator == "brand" && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleInvite()}
                            size="small"
                          >
                            Resubmit
                          </Button>
                        )}


                      {brandQuote && influencerQuote && currentFlow == 4 && currentNegotiator == "brand" && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleViewContract()}
                            size="small"
                          >
                            View Contract
                          </Button>
                        )}


                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => rejectCampaign()}
                        sx={{
                          "&:hover": {
                            color: "#FF4842",
                          },
                        }}
                      >
                        <Typography variant="caption">
                          {brandQuote ? "Decline Offer" : "Decline Invite"}{" "}
                          &nbsp;
                        </Typography>
                        <CancelIcon fontSize="small" color="error" />
                      </Stack>
                    </Grid>
                  }
                </Grid>
              }
            </>
          )}
        </Stack>
      </Stack>
      {openInterestModal && (
        <Modal
          open={openInterestModal}
          onClose={() => setOpenInterestModal(false)}
          aria-labelledby="interest-modal"
        >
          <Box
            sx={{
              padding: 3,
              backgroundColor: "#FFFFFF",
              width: isMobile ? 320 : 700,
              maxHeight: isMobile ? 600 : "90%",
              marginX: "auto",
              marginTop: 5,
              borderRadius: 4,
              overflow: "scroll",
            }}
          >
            <Typography
              variant="h5"
              style={{ marginBottom: 20 }}
              align="center"
            >
              {activeFilter === "Invited"
                ? "Respond To Invite"
                : "Apply For Campaign"}
            </Typography>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item sx={12} md={5}>
                <img
                  src={coverPic}
                  alt="campaign-cover"
                  style={{
                    width: 300,
                    height: "auto",
                    objectFit: "contain",
                    margin: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} container alignItems="center">
                <Stack
                  direction="column"
                  sx={{
                    marginTop: "10px",
                    marginRight: "10px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" sx={{marginBottom:1}}>
                    Start Date: {formatDate(startDate)}
                  </Typography>

                  <Typography variant="subtitle1"  sx={{marginBottom:1}}>
                    End Date: {formatDate(endDate)}
                  </Typography>
                  <Typography variant="subtitle1" color="grey">
                    The brand is interested in:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox checked={includesReels} />}
                    label="Reels"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesStories} />}
                    label="Stories"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesVideos} />}
                    label="TikTok Posts"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesPictures} />}
                    label="Feed Posts"
                  />
                </Stack>
              </Grid>

              {commissionModel && (<FormControl sx={{ width: "100%", marginBottom: 4, marginTop: 4 }}>
                <InputLabel
                  id="opt-in-for-the-affiliate-commission-model"
                  sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
                >
                  Opt in for the Affiliate commission model
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="opt-in-for-the-affiliate-commission-model"
                  id="opt-in-for-the-affiliate-commission"
                  label="Opt in for the Affiliate commission model"
                  value={influencerCommissionModel}
                  onChange={(e) =>
                    setInfluencerCommissionModel(e.target.value)
                  }
                >
                  {[{label: 'Yes', value: true}, {label: 'No', value: false}].map((option) => (
                    <MenuItem
                      value={option.value}
                      key={option.value + "commission-model" + option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ overflowY: "scroll" }}
              justifyContent="center"
            >
              <Typography
                variant="body1"
                sx={{
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  marginY: 1,
                  width: "100%",
                }}
              >
                <b>Brief:</b> <br /> {description}
              </Typography>
              <div style={{display: "flex", marginTop: "5px"}}>
                <CurrencyType currencyType={currencyType} setCurrencyType={setCurrencyType}/>
                <TextField
                  variant="standard"
                  id="offer"
                  label="My Offer"
                  placeholder=""
                  type="number"
                  onChange={(e) => setMyQuote(e.target.value)}
                />
              </div>
            </Grid>
            {activeFilter === "New" && (
              <TextField
                fullWidth
                variant="standard"
                id="description"
                label="Description"
                multiline
                rows={4}
                placeholder="Please explain how you can contribute to the campaign"
                onChange={(e) => debouncedUserInput(e.target.value)}
                sx={{ marginBottom: 3 }}
              />
            )}
            <Grid
              item
              xs={12}
              container
              sx={{ overflowY: "scroll" }}
              justifyContent="center"
            >
              <Typography variant="h5" style={{ marginBottom: 20 }}>
                Please Read The Contract Carefully
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: defaultContract }} />
              <br />
              {extendedContract && (
                <div dangerouslySetInnerHTML={{ __html: extendedContract }} />
              )}
            </Grid>
            <FormControlLabel
              label="I have read the terms and agree to them"
              control={
                <Checkbox
                  checked={agreed}
                  onClick={() => setAgreed(!agreed)}
                  sx={{ color: "#FFA3C7" }}
                />
              }
              sx={{
                width: "100%",
                justifyContent: "center",
                marginBottom: 1,
                // backgroundColor: "#FFA3C7",
                color: "#FFA3C7",
              }}
            />

            <Button
              variant="contained"
              sx={{ marginRight: 2, marginBottom: "25px" }}
              onClick={() =>
                activeFilter === "Invited" ? respondToInvite() : showInterest()
              }
              disabled={myQuote === 0 || !agreed}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenInterestModal(false)}
              sx={{ marginBottom: "25px" }}
            >
              Cancel
            </Button>
            <br />
            <Link
              to={`/customContract/${id}`}
              component={RouterLink}
              sx={{
                backgroundColor: "#FFA3C7",
                color: "#FFF",
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 5,
                paddingRight: 5,
                margin: "auto",
              }}
            >
              Propose Changes
            </Link>
          </Box>
        </Modal>
      )}
      
      <RestrictedCampaign openRestrictedModal={openRestrictedModal} setOpenRestrictedModal={setOpenRestrictedModal}/>

      {viewContractModal && (
        <Modal
          open={viewContractModal}
          onClose={() => setViewContractModal(false)}
          aria-labelledby="interest-modal"
        >
        <Box
            sx={{
              padding: 3,
              backgroundColor: "#FFFFFF",
              width: isMobile ? 320 : 700,
              maxHeight: isMobile ? 600 : "90%",
              marginX: "auto",
              marginTop: 5,
              borderRadius: 4,
              overflow: "scroll",
            }}
          >
          <div dangerouslySetInnerHTML={{ __html: defaultContract }} />

            <br />
              {extendedContract && (
                <>
                <b>Additional Terms by Brand</b>
                <div dangerouslySetInnerHTML={{ __html: extendedContract }} />
                </>
              )}

            <br />
              {extendedInfluencerContract && (
                <>
                <b>Additional Terms by influencer</b>
                <div dangerouslySetInnerHTML={{ __html: extendedInfluencerContract }} />
                </>
              )}

        </Box>
        </Modal>
        )}
    </Card>
  );
};
PaidCampaignCard.propTypes = {
  campaign: PropTypes.object.isRequired,
  activeFilter: PropTypes.string.isRequired,
  influencer: PropTypes.object.isRequired,
};