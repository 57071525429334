import { gql } from "@apollo/client";

const GET_MY_TIKTOK_STATS = gql`
  query getMyTiktokStats($userId: ID!) {
    getMyTiktokStats(userId: $userId) {
      id
      accountVisibility
      accountsEngaged
      videoViews
      femaleRatio
      following
      link
      maleRatio
      topAgeRange
      topCity
      topCityPercentage
      topCountry
      topCountryPercentage
      videoViewsGrowthRate
      profileViews
      profileViewsGrowthRate
      likes
      likesGrowthRate
      comments
      commentsGrowthRate
      commission
      commissionType
    }
  }
`;

export default GET_MY_TIKTOK_STATS;
