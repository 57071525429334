import { gql } from "@apollo/client";

const GET_MY_INSTAGRAM_STATS = gql`
  query getInstagramStats($userId: ID!) {
    getMyInstagramStats(userId: $userId) {
      id
      link
      following
      followingGrowthRate
      topCountry
      topCountryPercentage
      topCity
      topCityPercentage
      femaleRatio
      maleRatio
      accountsEngaged
      accountsEngagedGrowthRate
      accountVisibility
      accountsReached
      topAgeRange
      accountsReachedGrowthRate
    }
  }
`;

export default GET_MY_INSTAGRAM_STATS;
