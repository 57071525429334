import { gql } from "@apollo/client";

const CUSTOM_RESPONSE = gql`
  mutation influencerRespondWithContract(
    $campaignId: ID!
    $influencerId: ID!
    $influencerQuote: Int!
    $description: String
    $contract: String!
    $influencerQuoteType: String!
    $commissionModel: Boolean 
  ) {
    influencerRespondWithContract(
      campaignId: $campaignId
      influencerId: $influencerId
      influencerQuote: $influencerQuote
      description: $description
      contract: $contract
      influencerQuoteType: $influencerQuoteType
      commissionModel: $commissionModel
    )
  }
`;

export default CUSTOM_RESPONSE;
