import { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { currencies } from './currencies';

const CurrencyType = ({ currencyType, setCurrencyType, width }) => {

  return (
    <FormControl variant="standard" sx={{ width: width, marginRight: "10px" }}>
      <InputLabel
        id="currency-type-label"
        sx={{ color: "#EF2E83"}}
      >
        Select Currency
      </InputLabel>
     <Select
      labelId="currency-type-label"
      id="currency-type"
      value={currencyType}
      onChange={(e) =>
        setCurrencyType(e.target.value)
      }
    >
      {currencies.map((option) => (
        <MenuItem
          value={option.value}
          key={option.value + "currency-type"}
        >
          {option.symbol}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
  );
};

CurrencyType.defaultProps = {
  currencyType: 'USD',
  width: '100%'
};


export default CurrencyType;
