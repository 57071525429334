import { useState, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListItem,
  Grid,
  ListItemAvatar,
  Link
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useMutation, useQuery } from "@apollo/client";
import FETCH_NOTIFICATIONS from "../../../graphql/queries/fetchNotifications";
import MARK_AS_READ from "../../../graphql/mutations/markAsRead";
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { data: notifications, startPolling } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    pollInterval: 500,
  });

  const [markAsRead] = useMutation(MARK_AS_READ, {
    refetchQueries: ["influencerGetNotifications"],
  });

  const totalUnRead = notifications?.influencerGetNotifications.length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    startPolling(5000);
  }, [notifications, startPolling]);

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            height: 400,
            overflowY: "scroll",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <List
          disablePadding
        // subheader={
        //   <ListSubheader
        //     disableSticky
        //     sx={{ py: 1, px: 2.5, typography: "overline" }}
        //   >
        //     New
        //   </ListSubheader>
        // }
        >
          {notifications?.influencerGetNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              markAsRead={markAsRead}
            />
          ))}
        </List>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification, markAsRead }) {
  const { avatar, title } = renderContent(notification);

  return (
    <Link to={notification.url} component={RouterLink}>
      <ListItem
        sx={{
          py: 1,
          px: 2.5,
          mt: "1px",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Grid item xs container justifyContent="space-between">
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: "flex",
                  alignItems: "center",
                  color: "text.disabled",
                }}
              >
                <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                {new Date(notification.createdAt).toLocaleDateString()}
              </Typography>
              <Button
                variant="text"
                sx={{
                  mt: 0.5,
                  display: "flex",
                  alignItems: "center",
                  color: "text.disabled",
                  fontSize: "0.4rem",
                }}
                size="small"
                onClick={() => {
                  markAsRead({ variables: { id: notification.id } });
                }}
              >
                Mark As Read &nbsp; <DoneAllIcon sx={{ width: 10, height: 10 }} />
              </Button>
            </Grid>
          }
        />
      </ListItem>
    </Link>

  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  let campaignName = notification.text.split(":")[1];
  const title = (
    <Typography variant="subtitle2">
      {campaignName && <b>{`Campaign: ${campaignName}`}
        <br />
      </b>
      }
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {notification.text.split(":")[0]}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
