import { gql } from "@apollo/client";

const GET_CANDIDATE_CAMPAIGNS = gql`
  query getCandidateCampaignsForInfluencer($userId: ID!) {
    getCandidateCampaignsForInfluencer(userId: $userId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      commissionModel
      startDate
      endDate
      campaignContracts {
        id
        userId
        influencerQuote
        contractType
        influencerQuoteType
        campaignTasks {
          description
        }
      }
    }
  }
`;

export default GET_CANDIDATE_CAMPAIGNS;
