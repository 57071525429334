import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Fab,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  CardHeader,
  Avatar,
  IconButton,
  Box
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makeStyles } from "@mui/styles";
import GET_CHATROOM_MESSAGES from "../../../graphql/queries/getChatroomMessages";
import { useMutation, useQuery } from "@apollo/client";
import SEND_MESSAGE from "../../../graphql/mutations/sendMessage";
import { MessageLeft, MessageRight } from "./messageBubble";
import { CircularLoader } from "../../../components/loaders";
import { ChatAttachmentUploader } from "./chatAttachmentUploader";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: "100%",
  },
  chatSection: {
    width: "100%",
    height: "60vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "65vh",
    overflowY: "auto",
  },
  headerChat: {
    backgroundColor:'#FFF',
    // height: '90px',
    boxShadow:'0px 3px 2px rgba(0,0,0,0.100)',
    display: 'flex',
    alignItems: 'center',
    padding: '5px'
  }
});
const currentUser = localStorage.getItem("currentUser");

const Chatroom = ({ id, brand, sidebarOpen, handleSidebar, chatroom }) => {
  const classes = useStyles();
  const [myMessage, setMyMessage] = useState("");
  const [myAttachment, setMyAttachment] = useState("");
  const [isActive, setIsActive] = useState(true);
  const activityTimeoutRef = useRef(null);
  const [attachmentPopup, setAttachmentPopup] = useState(false);
  const chatContainerRef = useRef(null);

  const { data: messages, stopPolling, startPolling } = useQuery(GET_CHATROOM_MESSAGES, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
      chatroomId: id,
    },
    pollInterval: isActive ? 1000 : 1000000,
  });

  const resetActivityTimeout = () => {
    clearTimeout(activityTimeoutRef.current);
    setIsActive(true);
    activityTimeoutRef.current = setTimeout(() => {
      setIsActive(false);
    }, 1 * 60 * 1000);
  };

  useEffect(() => {
    resetActivityTimeout();

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, resetActivityTimeout);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetActivityTimeout);
      });
      clearTimeout(activityTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }, [isActive, startPolling, stopPolling]);

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    refetchQueries: ["getChatroomMessages"],
  });

  const handleMessageSend = () => {
    sendMessage({
      variables: {
        chatroomId: id,
        userId: currentUser,
        message: myMessage,
        attachment: myAttachment,
      },
    });
    setMyMessage("");
    setMyAttachment("");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Trigger scroll when messages change

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      // Scroll to the bottom of the chat container
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  if (messages === undefined)
    return <CircularLoader title="Loading messages..." />;
  return (
    <Grid item xs={sidebarOpen ? 8 : 12}>
      <CardHeader
        className={classes.headerChat}
        title={brand?.name}
        subheader={chatroom?.campaign?.name}
        action={
          !sidebarOpen && (
            <IconButton onClick={handleSidebar} sx={{ order: 2, marginLeft: '-10px', padding: '10px' }}>
              {sidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            </IconButton>
          )
        }
        avatar={
          <Box sx={{ display: 'flex', alignItems: 'center', order: 2 }}>
            <Avatar alt={brand?.name} src={brand?.image} />
          </Box>
        }
      />
      <List ref={chatContainerRef} className={classes.messageArea}>
        {messages.getChatroomMessages.map((message) => {
          return (
            <ListItem key={`${message.sender}-${message.id}`}>
              <ListItemText>
                {message.sender.toString() !== currentUser.toString() ? (
                  <MessageLeft
                    message={message.text}
                    attachment={message.attachment}
                    timestamp={message.createdAt}
                  />
                ) : (
                  <MessageRight
                    message={message.text}
                    attachment={message.attachment}
                    timestamp={message.createdAt}
                  />
                )}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Grid item xs container style={{ padding: "20px" }}>
        <Grid item xs={9}>
          <TextField
            id="outlined-basic-email"
            label="Type Something"
            fullWidth
            value={myMessage}
            onChange={(e) => setMyMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleMessageSend();
              }
            }}
          />
        </Grid>
        <Grid item xs={3} align="right">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => handleMessageSend()}
              disabled={myMessage === '' && myAttachment === ''}
              sx={{marginLeft: '10px'}}
            >
              <SendIcon />
            </Fab>
            {!sidebarOpen && <Fab
              color="primary"
              aria-label="add"
              onClick={() => setAttachmentPopup(true)}
              sx={{marginLeft: '10px'}}
            >
              <AttachFileIcon />
            </Fab>}
          </div>
        </Grid>
      </Grid>
      {attachmentPopup && (
        <ChatAttachmentUploader
          open={attachmentPopup}
          setOpen={setAttachmentPopup}
          currentUserId={currentUser}
          chatroomId={id}
        />
      )}
    </Grid>
  );
};

export default Chatroom;
