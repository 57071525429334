import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  Box,
  Card,
  Typography,
  Stack,
  Modal,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Link,
} from "@mui/material";
import { useMutation } from "@apollo/client";

import RedeemIcon from "@mui/icons-material/Redeem";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";

import Label from "../../../components/label";
import { useState } from "react";
import NOT_INTERESTED from "../../../graphql/mutations/notInterestedInCampaign";
import SHOW_GIFTED_INTEREST from "../../../graphql/mutations/interestedInGiftedCampaign";
import ACCEPT_GIFTED_CAMPAIGN from "../../../graphql/mutations/influencerAcceptGiftedContract";
import { Link as RouterLink } from "react-router-dom";
import RestrictedCampaign from "./RestrictedCampaign";
import { formatDate } from "../../../components/dateFormatter";

// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectPosition: "top",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------
const campaignMedia = {
  All: "TikTok & Instagram",
  TikTok: "TikTok",
  Instagram: "Instagram",
};
GiftedCampaignCard.propTypes = {
  product: PropTypes.object,
};
const userAgent =
  typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );

function debounce(func, delay = 250) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default function GiftedCampaignCard({ campaign, activeFilter, influencer }) {
  const {
    id,
    name,
    coverPic,
    media,
    includesStories,
    includesReels,
    includesVideos,
    includesPictures,
    description,
    status,
    commissionModel,
    startDate,
    endDate,
    user
  } = campaign;
  const [myDescription, setDescription] = useState("");
  const [influencerCommissionModel, setInfluencerCommissionModel] = useState(false);
  const [openInterestModal, setOpenInterestModal] = useState(false);
  const [openRestrictedModal, setOpenRestrictedModal] = useState(false);
  const debouncedUserInput = debounce((e) => setDescription(e));

  const [showInterest] = useMutation(SHOW_GIFTED_INTEREST, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
      description: myDescription,
      commissionModel, influencerCommissionModel,
    },
    onCompleted: () => {
      setDescription("");
      setOpenInterestModal(false);
      window.location.reload();
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });

  const [notInterested] = useMutation(NOT_INTERESTED, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });

  const [acceptOffer] = useMutation(ACCEPT_GIFTED_CAMPAIGN, {
    variables: {
      campaignId: id,
      influencerId: localStorage.getItem("currentUser"),
    },
    onCompleted: () => {
      setDescription("");
      setOpenInterestModal(false);
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });

  const handleInterest = () => {
    if(influencer.isActive){
      setOpenInterestModal(true);
    }
    else{
      setOpenRestrictedModal(true)
    }
  };

  const handleInvite = () => {
    setOpenInterestModal(true);
  }

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Label
          variant="filled"
          color="primary"
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 2,
          }}
        >
          <RedeemIcon />
        </Label>
        <Typography
          variant="subtitle1"
          sx={{
            zIndex: 9,
            bottom: 0,
            left: 0,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 1,
            background: "rgba(0, 0, 0, 0.7)",
            width: "100%",
          }}
        >
          {user && user.brandName ? `${user.brandName} - ${name}` : name}
        </Typography>
        <StyledProductImg alt={name} src={coverPic} />
      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Typography variant="subtitle1" noWrap align="center">
          {campaignMedia[media]} Campaign
        </Typography>

        <Grid item xs container direction="column" alignItems="center">
          { startDate && <Typography variant="caption">Start Date: {formatDate(startDate)}</Typography> }
          { endDate && <Typography variant="caption">End Date: {formatDate(endDate)}</Typography> }
        </Grid>
        
        {status === "Cancelled" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Cancelled
          </Typography>
        )}
        {status === "In_Progress" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Click To Submit Content
          </Typography>
        )}
        {status === "Approved" && activeFilter === "Active" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Campaign Not Yet Started
          </Typography>
        )}

        {status === "Approved" && activeFilter === "Active" && (
          <Link
            to={
              `/campaign/${campaign.id}/details`
            }
            component={RouterLink}
            sx={{
              textDecoration: "none",
              alignSelf:"center"
            }}
            color="error"
            variant="caption"
          >
            View Campaign Details
          </Link>
        )}
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          {activeFilter === "Interested" && (
            <>
            <Grid item xs container direction="column">
              <Button
                variant="outlined"
                color="error"
                onClick={() => notInterested()}
                size="small"
              >
                Withdraw From Campaign
              </Button>
            </Grid>

           
            </>
          )}
          {(activeFilter === "New" || activeFilter === "Old") && (
              <div style={{display: "flex", flexDirection: 'row'}}>
                <Stack direction="row" spacing={12} alignItems="center" justifyContent="center">
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => handleInterest()}
                      sx={{
                        "&:hover": {
                          color: "#FFA3C7",
                        },
                      }}
                  >
                    <Typography variant="caption">Interested &nbsp;</Typography>
                    <FavoriteIcon fontSize="small" color="primary"/>
                  </Stack>
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => notInterested()}
                      sx={{
                        "&:hover": {
                          color: "#FF4842",
                        },
                      }}
                  >
                    <Typography variant="caption">Not Interested &nbsp;</Typography>
                    <CancelIcon fontSize="small" color="error"/>
                  </Stack>
                </Stack>
              </div>
          )}
          {activeFilter === "Invited" && (
              <Grid item xs container direction="column">
                <Grid item xs container justifyContent="space-between">
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => handleInvite()}
                      sx={{
                        "&:hover": {
                          color: "#FFA3C7",
                        },
                      }}
                  >
                    <Typography variant="caption">Accept &nbsp;</Typography>
                    <FavoriteIcon fontSize="small" color="primary"/>
                  </Stack>

                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => notInterested()}
                  sx={{
                    "&:hover": {
                      color: "#FF4842",
                    },
                  }}
                >
                  <Typography variant="caption">Decline &nbsp;</Typography>
                  <CancelIcon fontSize="small" color="error" />
                </Stack>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Stack>
      {openInterestModal && (
        <Modal
          open={openInterestModal}
          onClose={() => setOpenInterestModal(false)}
          aria-labelledby="interest-modal"
        >
          <Box
            sx={{
              padding: 3,
              backgroundColor: "#FFFFFF",
              width: isMobile ? 350 : 450,
              maxHeight: isMobile ? 550 : 550,
              marginX: "auto",
              marginTop: 20,
              borderRadius: 4,
              overflow: "scroll",
            }}
          >
            <Typography
              variant="h4"
              style={{ marginBottom: 20 }}
              align="center"
            >
              {activeFilter === "Invited"
                ? "Respond To Invite"
                : "Apply For Campaign"}
            </Typography>
            <Grid item xs={12} mb={2}>
              <Typography variant="subtitle1" sx={{marginBottom:1}}>
                Start Date: { formatDate(startDate)}
              </Typography>

              <Typography variant="subtitle1" sx={{marginBottom:1}}>
                End Date: {formatDate(endDate)}
              </Typography>

              <Typography variant="subtitle1" color="grey">
                The brand is interested in:
              </Typography>
              <Stack
                direction={isMobile ? "column" : "row"}
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <FormControlLabel
                  control={<Checkbox checked={includesReels} />}
                  label="Reels"
                />
                <FormControlLabel
                  control={<Checkbox checked={includesStories} />}
                  label="Stories"
                />
                <FormControlLabel
                  control={<Checkbox checked={includesVideos} />}
                  label="TikTok Posts"
                />
                <FormControlLabel
                  control={<Checkbox checked={includesPictures} />}
                  label="Feed Posts"
                />
              </Stack>

              {commissionModel && (<FormControl sx={{ width: "100%", marginBottom: 4, marginTop: 4 }}>
                <InputLabel
                  id="opt-in-for-the-affiliate-commission-model"
                  sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
                >
                  Opt in for the Affiliate commission model
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="opt-in-for-the-affiliate-commission-model"
                  id="opt-in-for-the-affiliate-commission"
                  label="Opt in for the Affiliate commission model"
                  value={influencerCommissionModel}
                  onChange={(e) =>
                    setInfluencerCommissionModel(e.target.value)
                  }
                >
                  {[{label: 'Yes', value: true}, {label: 'No', value: false}].map((option) => (
                    <MenuItem
                      value={option.value}
                      key={option.value + "commission-model" + option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}



              <Typography
                variant="body1"
                sx={{
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  marginY: 1,
                }}
              >
                <b>Brief:</b> <br /> {description}
              </Typography>
              {activeFilter === "New" || activeFilter === "Old" && (
                <TextField
                  fullWidth
                  variant="standard"
                  id="description"
                  label="Description"
                  multiline
                  rows={4}
                  placeholder="Please explain how you can contribute to the campaign"
                  onChange={(e) => debouncedUserInput(e.target.value)}
                  sx={{ marginBottom: 3 }}
                />
              )}
            </Grid>
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={() => {
                activeFilter === "Invited" ? acceptOffer() : showInterest();
              }}
              disabled={myDescription === "" && activeFilter === "Interested"}
            >
              {activeFilter === "Invited" ? "Accept" : "Submit"}
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenInterestModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      )}

        <RestrictedCampaign openRestrictedModal={openRestrictedModal} setOpenRestrictedModal={setOpenRestrictedModal}/>
    </Card>
  );
}
